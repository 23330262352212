import { Injectable } from '@angular/core';
import { Rule } from './rule.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class RuleService {
  model: Rule;
  baseUrl = environment.baseUrl;
  readonly rootURL = this.baseUrl;
  list: Rule[];


  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  postRule(model: Rule): Observable<any> {
    return this.http.post<any>(this.rootURL + "/General/PostRule/", JSON.stringify(this.model), this.httpOptions);
  }

  updateRule(model: Rule): Observable<any> {
    return this.http.post<any>(this.rootURL + "/General/UpdateRule/" + this.model.Id, JSON.stringify(this.model), this.httpOptions)
  }

  getRules() {
    this.http.get(this.rootURL + "/General/GetRules/").toPromise().then(res => this.list = res as Rule[]);
  }

  getRule(id): Observable<any> {
    return this.http.get<any>(this.rootURL + "/General/GetRule/" + id);
  }

  deleteRule(id): Observable<any> {
    return this.http.post<any>(this.rootURL + "/General/DeleteRule/" + id, this.httpOptions);
  }


}
