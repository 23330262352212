import { Component, OnInit } from '@angular/core';
import { Rule } from '../rule.model';
import { RuleService } from '../rule.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-rule-addedit',
  templateUrl: './rule-addedit.component.html',
})
export class RuleAddEditComponent implements OnInit {
  model: Rule;

  constructor(public _ruleService: RuleService, private toaster: ToastrService, private route: ActivatedRoute, private router: Router) {
    this.resetForm();
  }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
      if (params["id"] != null) {
        this._ruleService.getRule(params["id"]).subscribe(result => {
          this.model = result;

          this._ruleService.model = {
            Id: result["Id"],
            DescriptionSq: result["DescriptionSq"],
            DescriptionEn: result["DescriptionEn"],
            DescriptionSr: result["DescriptionSr"],
            PhotoName: result["PhotoName"],
            IsFeature: result["IsFeature"],
            IsDeleted: result["IsDeleted"],
            CreateOnDate: result["CreateOnDate"],
            LastModifiedOnDate: result["LastModifiedOnDate"]
          }
        })
      } else {
        this.resetForm();
      }
    })
  }

  resetForm(form?: NgForm) {
    if (form != null)
      form.resetForm();
    this._ruleService.model = {
      Id: 0,
      DescriptionSq: '',
      DescriptionEn: '',
      DescriptionSr: '',
      PhotoName:'',
      IsFeature: false,
      IsDeleted: false,
      CreateOnDate: null,
      LastModifiedOnDate: null

    }
  }

  onFormSubmit(form: NgForm) {
    this.route.params.subscribe((params: Params) => {
      if (params["id"] == 0 || params["id"] == null) {
        this.insertRule(form);
      } else {
        this.updateRule(form);
      }
    });
  }

  insertRule(form: NgForm) {
    this._ruleService.postRule(form.value).subscribe(result => {
      this.toaster.success("Submitted successfully", 'Add Rule');
      this._ruleService.getRules();
      this.router.navigate(["/rule-list"]);
    

    },
      error => {
        this.toaster.error('One error while adding Rule', 'Add rule');
        console.log(error);
      }

    );
  }

  updateRule(form: NgForm) {
    this._ruleService.updateRule(form.value).subscribe(result => {
      this.toaster.success("Submitted successfully", 'Update Rule');
      this._ruleService.getRules();
      this.router.navigate(["/rule-list"]);


    },
      error => {
        this.toaster.error('One error while updating Rule', 'update rule');
        console.log(error);
      }

    );
  }

}
