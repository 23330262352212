import { Component, OnInit } from '@angular/core';
import { TermsService } from 'src/app/terms/terms.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FAQ } from 'src/app/faq/faq.model';
import { ActivatedRoute, Params, Router } from '@angular/router';


@Component({
  selector: 'app-terms-addedit',
  templateUrl: './terms-addedit.component.html'
})
export class TermsAddEditComponent implements OnInit {
  model: FAQ;

  constructor(public _termsService: TermsService, private toaster: ToastrService, private route: ActivatedRoute, private router: Router) {
    this.resetForm();
  }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
      if (params["id"] != null) {

        this._termsService.getTerm(params["id"]).subscribe(result => {

          this.model = result;
        
          this._termsService.model = {
            CreateOnDate: result["CreateOnDate"],
            Id: result["Id"],
            IsDeleted: result["IsDeleted"],
            LastModifiedOnDate: result["LastModifiedOnDate"],
            DescriptionSq: result["DescriptionSq"],
            DescriptionEn: result["DescriptionEn"],
            DescriptionSr: result["DescriptionSr"],
            Version: result["Version"]

          }
        }
        )
      } else {
        this.resetForm();
      }
    });

  }



  resetForm(form?: NgForm) {
    if (form != null)
      form.resetForm();
    this._termsService.model = {
      Id: 0,
      DescriptionSq: '',
      DescriptionEn: '',
      DescriptionSr: '',
      Version: '',
      CreateOnDate: null,
      LastModifiedOnDate: null,
      IsDeleted: false,
    }
  }

  onSubmit(form: NgForm) {
    this.route.params.subscribe((params: Params) => {
      if (params["id"] == 0 || params["id"] == null) {
        this.insertRecord(form);
      }
      else {
        this.updateRecord(form);
      }
    });


  }

  insertRecord(form: NgForm) {
    this._termsService.postTerm(form.value).subscribe(
      res => {
        this.toaster.success('Submitted successfully', 'Add Country');
        this.router.navigate(['/terms-list']);
        this._termsService.getTerms();
      },
      err => {
        console.log(err);
      }
    )
  }

  updateRecord(form: NgForm) {
    this._termsService.updateTerm().subscribe(
      res => {

        this.toaster.success('Submitted successfully', 'Update Country');
        this.router.navigate(['/terms-list']);
        this._termsService.getTerms();
      },
      err => {
        this.toaster.error('One error while updating Country', 'Update Country');
        console.log(err);
      }
    )
  }
}

