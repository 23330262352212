import { Component, OnInit } from '@angular/core';
import { UserLogin } from 'src/app/user/login.model';
import { NgForm } from '@angular/forms';
import { LoginService } from 'src/app/user/login.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {

  model: UserLogin;
  private jwtHelper = new JwtHelperService();

  constructor(public _userService: LoginService, private router: Router, private toaster: ToastrService) { }

  ngOnInit() {
    this.resetForm();

    if (localStorage.getItem('token') != null && !this.jwtHelper.isTokenExpired(localStorage.getItem('token')))
      this.router.navigateByUrl('');

    //console.log(this._userService.model.UserName)
  }

  onSubmit(form: NgForm) {
    this._userService.postLogin(form.value).subscribe(
      (res: any) => {
        localStorage.setItem('token', res.token);
        this._userService.setIsAuthenticated(true);
        this.router.navigateByUrl('');
      },
      err => {
        if (err.status == 400)
          this.toaster.error("Incorrect username or password.", "Authentication faild.");
        else  
          console.log(err);

      }
    )
  }

  resetForm(form?: NgForm) {
    if (form != null)
      form.resetForm();
    this._userService.model = {    
      UserName: '',
      Password: ''
    }
  }
}

