import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
})
export class ConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }
}
