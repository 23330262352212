import { Component, OnInit } from '@angular/core';
import { FAQService } from 'src/app/faq/faq.service';
import { FAQ } from 'src/app/faq/faq.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html'
})
export class FAQListComponent implements OnInit {

  constructor(public _faqService: FAQService, private toaster: ToastrService) { }

  ngOnInit() {
    this._faqService.getFeqList();
  }


  deleteFaq(id) {
    this._faqService.deleteFaq(id).subscribe(
      res => {

        this.toaster.success('Deleted successfully', 'Delete Country');
        this._faqService.getFeqList();
      },
      err => {
        this.toaster.error('One error while updating Country', 'Update Country');
        console.log(err);
      }
    )


  }

  //populateForm(c: Country) {
  //  this.countryservice.formData = Object.assign({},c);
  //}

}
