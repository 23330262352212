import { Component, OnInit } from '@angular/core';
import { FAQService } from 'src/app/faq/faq.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FAQ } from 'src/app/faq/faq.model';
import { ActivatedRoute, Params, Router } from '@angular/router';


@Component({
  selector: 'app-faq-addedit',
  templateUrl: './faq-addedit.component.html'
})
export class FAQAddEditComponent implements OnInit {
  model: FAQ;

  constructor(public _faqService: FAQService, private toaster: ToastrService, private route: ActivatedRoute, private router: Router) {
    this.resetForm();
  }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
      if (params["id"] != null) {

        this._faqService.getFaq(params["id"]).subscribe(result => {

          this.model = result;
          console.log(result);
          this._faqService.model = {
            CreateOnDate: result["CreateOnDate"],
            Id: result["Id"],
            IsDeleted: result["IsDeleted"],
            LastModifiedOnDate: result["LastModifiedOnDate"],
            QuestionSq: result["QuestionSq"],
            QuestionEn: result["QuestionEn"],
            QuestionSr: result["QuestionSr"],
            AnswerSq: result["AnswerSq"],
            AnswerEn: result["AnswerEn"],
            AnswerSr: result["AnswerSr"]

          }
        }
        )
      } else {
        this.resetForm();
      }
    });

  }



  resetForm(form?: NgForm) {
    if (form != null)
      form.resetForm();
    this._faqService.model = {
      Id: 0,
      QuestionSq: '',
      QuestionEn: '',
      QuestionSr: '',
      AnswerSq: '',
      AnswerEn: '',
      AnswerSr:'',
      CreateOnDate: null,
      LastModifiedOnDate: null,
      IsDeleted: false,
    }
  }

  onSubmit(form: NgForm) {
    this.route.params.subscribe((params: Params) => {
      if (params["id"] == 0 || params["id"] == null) {
        this.insertRecord(form);
      }
      else {
        this.updateRecord(form);
      }
    });


  }

  insertRecord(form: NgForm) {
    this._faqService.postFaq(form.value).subscribe(
      res => {
        this.toaster.success('Submitted successfully', 'Add Country');
        this.router.navigate(['/faq-list']);
        this._faqService.getFeqList();
      },
      err => {
        console.log(err);
      }
    )
  }

  updateRecord(form: NgForm) {
    this._faqService.updateFaq().subscribe(
      res => {

        this.toaster.success('Submitted successfully', 'Update Country');
        this.router.navigate(['/faq-list']);
        this._faqService.getFeqList();
      },
      err => {
        this.toaster.error('One error while updating Country', 'Update Country');
        console.log(err);
      }
    )
  }
}

