import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserLogin } from './login.model';
import { environment } from 'src/environments/environment';
import { ChangePassword } from './changepassword.model';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})


export class LoginService {

  model: UserLogin;
  changePasswordModel: ChangePassword
  baseUrl = environment.baseUrl
  readonly rootURL = this.baseUrl;

  private isLoggedIn: boolean = false;
  private jwtHelper = new JwtHelperService();

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  postLogin(model: UserLogin): Observable<any> {
    return this.http.post<any>(this.rootURL + "/General/Login", JSON.stringify(this.model), this.httpOptions)
  }

  changePassword(changePasswordModel: ChangePassword): Observable<any> {
    return this.http.post<any>(this.rootURL + "/General/ChangePassword", JSON.stringify(this.changePasswordModel), this.httpOptions)

  }

  public isAuthenticated(): boolean {
    return this.isLoggedIn && !this.jwtHelper.isTokenExpired(localStorage.getItem('token'));
  }

  public setIsAuthenticated(value: boolean) {
    this.isLoggedIn = value;
  }

  public getToken() {
    if (localStorage.getItem('token') != null)
      return localStorage.getItem('token');
  }
}


