import { Injectable } from '@angular/core';
import { Comment } from './comment.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class CommentService {
  baseUrl = environment.baseUrl;
  readonly rootURL = this.baseUrl;
  list: Comment[];


  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getComments() {
    this.http.get(this.rootURL + "/General/GetComments/").toPromise().then(res => this.list = res as Comment[]);
  }

}
