import { Component, OnInit } from '@angular/core';
import { TermsService } from 'src/app/terms/terms.service';
import { Terms } from 'src/app/terms/terms.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-terms-list',
  templateUrl: './terms-list.component.html'
})
export class TermsListComponent implements OnInit {

  constructor(public _termsService: TermsService, private toaster: ToastrService) { }

  ngOnInit() {
    this._termsService.getTerms();
  }


  //deleteFaq(id) {
  //  this._termsService.deleteTerm(id).subscribe(
  //    res => {

  //      this.toaster.success('Deleted successfully', 'Delete Country');
  //      this._termsService.getTerms();
  //    },
  //    err => {
  //      this.toaster.error('One error while updating Country', 'Update Country');
  //      console.log(err);
  //    }
  //  )


  //}

  //populateForm(c: Country) {
  //  this.countryservice.formData = Object.assign({},c);
  //}

}
