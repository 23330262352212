import { Injectable } from '@angular/core';
import { Terms } from './terms.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})


export class TermsService {
  model: Terms;
  baseUrl = environment.baseUrl;
  readonly rootURL = this.baseUrl;
  list: Terms[];

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  postTerm(model: Terms): Observable<any> {
    return this.http.post<any>(this.rootURL + "/General/PostTerm/", JSON.stringify(this.model), this.httpOptions)
  }

  updateTerm(): Observable<any> {
    return this.http.post<any>(this.rootURL + "/General/UpdateTerm/" + this.model.Id, JSON.stringify(this.model), this.httpOptions)
  }

  getTerms() {
    this.http.get(this.rootURL + "/General/GetTermsAndConditions/").toPromise().then(res => this.list = res as Terms[]);
  }

  getTerm(id): Observable<any> {
    return this.http.get<any>(this.rootURL + "/General/GetTerm/" + id);
  }
}
