import { Injectable } from '@angular/core';
import { FAQ } from './faq.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class FAQService {
  model: FAQ;
  baseUrl = environment.baseUrl
  readonly rootURL = this.baseUrl;
  list: FAQ[];

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  postFaq(model: FAQ): Observable<any> {
    return this.http.post<any>(this.rootURL + "/General/PostFaq/", JSON.stringify(this.model), this.httpOptions)
  }

  updateFaq(): Observable<any> {
    return this.http.post<any>(this.rootURL + "/General/UpdateFaq/" + this.model.Id, JSON.stringify(this.model), this.httpOptions)
  }

  getFeqList() {
    this.http.get(this.rootURL + "/General/GetFaqList/").toPromise().then(res => this.list = res as FAQ[]);
  }

  getFaq(id): Observable<any> {
    return this.http.get<any>(this.rootURL + "/General/GetFaq/" + id);
  }

  deleteFaq(id): Observable<any> {
    return this.http.post<any>(this.rootURL + "/General/DeleteFaq/" + id, this.httpOptions);
  }
}
