import { Component, OnInit } from '@angular/core';
import { CountryService } from 'src/app/country/country.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Country } from 'src/app/country/country.model';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-country-addedit',
  templateUrl: './country-addedit.component.html',
  styleUrls: ['./country-addedit.component.css']
})
export class CountryDetailComponent implements OnInit {

  model: Country;

  

  constructor(public service: CountryService, private toaster: ToastrService, private route: ActivatedRoute, private router: Router) {
    this.resetForm();
    console.log(this.service.token)
  }

  ngOnInit() {
    //this.resetForm();

    this.route.params.subscribe((params: Params) => {
      if (params["id"] != null) {

        this.service.getCountry(params["id"]).subscribe(result => {

          //this.model = result;
          console.log(result);
          this.service.model = {
            CreateOnDate: result["CreateOnDate"],
            CountryId: result["CountryId"],
            IsDeleted: result["IsDeleted"],
            LastModifiedOnDate: result["LastModifiedOnDate"],
            NameSq: result["NameSq"],
            NameEn: result["NameEn"],
            NameSr: result["NameSr"],
            Latitude: result["Latitude"],
            Longitude: result["Longitude"]

          }
        }
        )
      } else {
        this.resetForm();
      }
    });

  }


  resetForm(form?: NgForm) {
    if (form !=null)
    form.resetForm();
    this.service.model = {
      CountryId: 0,
      NameSq: '',
      NameEn: '',
      NameSr: '',
      Latitude: '',
      Longitude:'',
      CreateOnDate: null,
      LastModifiedOnDate: null,
      IsDeleted:false,
    }
  }

  onSubmit(form: NgForm) {
    this.route.params.subscribe((params: Params) => {
      if (params["id"] == 0 || params["id"] == null) {
        this.insertRecord(form);
      }
      else {
        this.updateRecord(form);
      }
    });
    
  
  }

  insertRecord(form: NgForm) {
    this.service.postCountry(form.value).subscribe(
      res => {
        this.toaster.success('Submitted successfully', 'Add Country');    
        this.router.navigate(['/country-list']);
        this.service.getCountries();
      },
      err => {
        console.log(err);
      }
    )
  }

  updateRecord(form: NgForm) {
    this.service.updateCountry().subscribe(
      res => {
      
        this.toaster.success('Submitted successfully', 'Update Country');
        this.router.navigate(['/country-list']);
        this.service.getCountries();
      },
       err => {      
         this.toaster.error('One error while updating Country', 'Update Country');
         console.log(err);
      }
    )
  }

  //populateForm(c: Country) {
  //  this.service.formData = Object.assign({}, c);
  //}


}
