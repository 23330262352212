import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'privacy-policy',
  templateUrl: './privacy-policy.component.html',
})
export class PrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }
}
