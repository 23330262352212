import { Injectable } from '@angular/core';
import { Country } from './country.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from '../user/login.service';

@Injectable({
  providedIn: 'root'
})


export class CountryService {
  model: Country;
  baseUrl = environment.baseUrl
  readonly rootURL = this.baseUrl;
  list: Country[];

  public token: string;

  constructor(private http: HttpClient, public _userService: LoginService) {
    this.token = this._userService.getToken();
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
      //'Authorization': `Bearer ${this._userService.getToken()}`

    })
  };

  postCountry(model: Country): Observable<any> {
    return this.http.post<any>(this.rootURL + "/General/PostCountry/", JSON.stringify(this.model), this.httpOptions)
  }

  updateCountry(): Observable<any> {
    return this.http.post<any>(this.rootURL + "/General/UpdateCountry/" + this.model.CountryId, JSON.stringify(this.model), this.httpOptions)
  }

  getCountries() {
   this.http.get(this.rootURL + "/General/GetCountries/").toPromise().then(res => this.list = res as Country[]);
  }

  getCountry(id): Observable<any> {
    return this.http.get<any>(this.rootURL + "/General/GetCountry/" + id);
  }

  deleteCountry(id): Observable<any> {
    return this.http.post<any>(this.rootURL + "/General/DeleteCountry/" + id, this.httpOptions);
  }
}
