import { Component, OnInit } from '@angular/core';
import { ChangePassword } from 'src/app/user/changepassword.model';
import { NgForm } from '@angular/forms';
import { LoginService } from 'src/app/user/login.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html'
})

export class ChangePasswordComponent implements OnInit {

  model: ChangePassword;


  constructor(public _userService: LoginService, private router: Router, private toaster: ToastrService) { }

  ngOnInit() {
    this.resetForm();
  }

  onSubmit(form: NgForm) {
    this._userService.changePassword(form.value).subscribe(
      (res: any) => {
        this.toaster.success("Password changed successfully.", "Success");
        this.resetForm();
      },
      err => {
        if (typeof err.error["0"] !== 'undefined') {
          if (err.error["0"].Code == "PasswordMismatch") {
            this.toaster.warning("Incorrect old password", "Info!");

          }
        }
        else if (err.status == 400) {
          this.toaster.warning(err.error.NewPassword || err.error.ConfirmPassword, "Info!");
        }
        else
          console.log(err);

      }
    )
  }

  resetForm(form?: NgForm) {
    if (form != null)
      form.resetForm();
    this._userService.changePasswordModel = {    
      OldPassword: '',
      NewPassword: '',
      ConfirmPassword:''
    }
  }
}

