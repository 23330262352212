import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../user/login.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
})

export class MainLayoutComponent implements OnInit {
  public isAuthenticated: boolean = false;
  private jwtHelper = new JwtHelperService();

  constructor(private router: Router, public _authService: LoginService) { }

  ngOnInit() {
    if (localStorage.getItem('token') != null)
      this.isAuthenticated = true;
  }

}
