import { Component, OnInit } from '@angular/core';
import { CountryService } from 'src/app/country/country.service';
import { Country } from 'src/app/country/country.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html'
})
export class CountryListComponent implements OnInit {

  constructor(public countryservice: CountryService, private toaster: ToastrService) { }

  ngOnInit() {
    this.countryservice.getCountries();
  }


  deleteCountry(id) {
    this.countryservice.deleteCountry(id).subscribe(
      res => {
       
        this.toaster.success('Deleted successfully', 'Delete Country');
        this.countryservice.getCountries();
      },
      err => {
        this.toaster.error('One error while updating Country', 'Update Country');
        console.log(err);
      }
    )


  }

  //populateForm(c: Country) {
  //  this.countryservice.formData = Object.assign({},c);
  //}

}
