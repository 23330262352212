import { Component, OnInit } from '@angular/core';
import { CommentService } from '../comment.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comment-list.component.html',
})
export class CommentListComponent implements OnInit {

  constructor(public _commentService: CommentService, private toaster: ToastrService) { }

  ngOnInit() {

    this._commentService.getComments();
  }

}
