import { Component, OnInit } from '@angular/core';
import { RuleService } from '../rule.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-rule-list',
  templateUrl: './rule-list.component.html',
})
export class RuleListComponent implements OnInit {

  constructor(public _ruleService: RuleService, private toaster: ToastrService) { }

  ngOnInit() {

    this._ruleService.getRules();
  }

  deleteRule(id) {
    this._ruleService.deleteRule(id).subscribe(
      res => {

        this.toaster.success('Deleted successfully', 'Delete Rule');
        this._ruleService.getRules();
      },
      err => {
        this.toaster.error('One error while updating Rule', 'Update Rule');
        console.log(err);
      }
    )


  }

}
