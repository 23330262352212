import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms"
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { CountryDetailComponent } from './country/country-add-edit/country-addedit.component';
import { CountryListComponent } from './country/country-list/country-list.component';
import { CountryService } from './country/country.service';
import { RouterModule, Routes } from '@angular/router';
import { ROUTES } from './app.routes'
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatCheckboxModule } from '@angular/material';

import { ToastrModule } from 'ngx-toastr';
import { HomeComponent } from './home/home.component';
import { RuleListComponent } from './rules/rule-list/rule-list.component';
import { RuleAddEditComponent } from './rules/rule-add-edit/rule-addedit.component';
import { RuleService } from './rules/rule.service';
import { FAQService } from './faq/faq.service';
import { FAQAddEditComponent } from './faq/faq-add-edit/faq-addedit.component';
import { FAQListComponent } from './faq/faq-list/faq-list.component';
import { TermsService } from './terms/terms.service';
import { TermsAddEditComponent } from './terms/terms-add-edit/terms-addedit.component';
import { TermsListComponent } from './terms/terms-list/terms-list.component';
import { LoginComponent } from './user/login/login.component';
import { LoginService } from './user/login.service';
import { ChangePasswordComponent } from './user/profile/changepassword.component';
import { PrivacyComponent } from './privacy/privacy-policy/privacy-policy.component';
import { LogoutComponent } from './user/logout/logout.component';
import { ConditionsComponent } from './termsandconditions/termsandconditions/termsandconditions.component';
import { CommentService } from './comment/comment.service';
import { CommentListComponent } from './comment/comment-list/comment-list.component';



@NgModule({
  declarations: [
    AppComponent, 
    CountryDetailComponent,
    CountryListComponent,
    MainLayoutComponent,
    HomeComponent,
    RuleListComponent,
    RuleAddEditComponent,
    FAQAddEditComponent,
    FAQListComponent,
    TermsAddEditComponent,
    TermsListComponent,
    LoginComponent,
    ChangePasswordComponent,
    PrivacyComponent,
    LogoutComponent,
    ConditionsComponent,
    CommentListComponent
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(ROUTES),
  ],
  providers: [CountryService, RuleService, FAQService, TermsService, LoginService, CommentService],
  bootstrap: [AppComponent]
})

export class AppModule { }
