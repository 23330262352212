import { Component, OnInit } from '@angular/core';
import { UserLogin } from 'src/app/user/login.model';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
import { MainLayoutComponent } from 'src/app/main-layout/main-layout.component';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})

export class LogoutComponent implements OnInit {

  constructor(private router: Router, public _userSevice: LoginService, public _main: MainLayoutComponent) { }

  ngOnInit() {

  }

  onLogout() {
    localStorage.removeItem('token');
    this._userSevice.setIsAuthenticated(false);
    this._main.isAuthenticated = false;
    this.router.navigateByUrl('');
  }

}

